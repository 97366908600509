
import { mapGetters, mapActions } from 'vuex'
export default {
  async asyncData({ app }) {
    const hotels = await app.$contentful
      .getEntries({
        locale: app.i18n.locale,
        content_type: 'hotel',
        order: '-fields.publishDate',
        limit: 8,
        'fields.name[exists]': true,
        'fields.thumbnail[exists]': true,
        'fields.prefecture[exists]': true,
        select:
          'sys.id,fields.popular,fields.options,fields.name,fields.occupation,fields.prefecture,fields.thumbnail,fields.plan,fields.category,fields.tags',
      })
      .then((res) => {
        return res.items
      })
      .catch((err) => {
        app.$sentry.captureException(err)
        return []
      })

    const sliderItemsResponce = await Promise.all(
      ['magazine', 'special'].map(
        async (contentType) =>
          await app.$contentful
            .getEntries({
              locale: app.i18n.locale,
              content_type: contentType,
              'fields.displayTopSlider[exists]': true,
              limit: 10,
            })
            .then((res) => {
              return res.items
            })
            .catch((err) => {
              app.$sentry.captureException(err)
              return []
            })
      )
    )
    const sliderItems = [
      ...sliderItemsResponce.map((type) =>
        type.map((item) => {
          return {
            type,
            id: item.sys.id,
            type: item.sys.contentType.sys.id,
            title: item.fields.title ?? '',
            desc: item.fields.ogdescription ?? '',
            link: item.fields.link ?? '',
            specialThumb: item.fields.pickupThumb
              ? item.fields.pickupThumb.fields.file.url
              : item.fields.thumbnail.fields.file.url
              ? item.fields.thumbnail.fields.file.url
              : '',
            magazineThumb: item.fields.image.fields.file.url ?? '',
            color: item.fields.color ? item.fields.color : 'var(--white)',
          }
        })
      ),
    ].flat(2)

    return {
      sliderItems,
      hotels,
    }
  },
  head() {
    return {
      titleTemplate: `%s`,
      link: [{ rel: 'canonical', href: `https://workation.app/` }],
    }
  },
  data() {
    return {
      magazines: [],
      workspaces: [],
      guidebooks: [],
      news: [],
      special: [],
      guide: [
        {
          heading: 'AREA',
          small: 'エリア一覧',
          href: '/area',
        },
        {
          heading: 'PROJECT',
          small: 'プロジェクト一覧',
          href: '/hotels',
        },
        {
          heading: 'HOTEL',
          small: 'ホテル一覧',
          href: '/hotels',
        },
        {
          heading: 'WORKSPACE',
          small: 'ワークスペース一覧',
          href: '/workspace',
        },
        {
          heading: 'MAGAZINE',
          small: 'マガジン一覧',
          href: '/magazine',
        },
        {
          heading: 'NEWS',
          small: 'お知らせ一覧',
          href: '/news',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      optionsFetching: 'options/fetching',
      optionsDone: 'options/done',
      options: 'options/items',
      areaFetching: 'area/fetching',
      areaDone: 'area/done',
      area: 'area/items',
    }),
    breadcrumbs() {
      const list = [
        {
          name: 'TOP',
          to: '/',
          url: `https://workation.app/`,
        },
      ]
      return list
    },
  },
  mounted() {
    if (!this.optionsDone && !this.optionsFetching) {
      this.optionsFetch()
    }
    if (!this.areaDone && !this.areaFetching) {
      this.areaFetch()
    }
    this.fetchSpecial()
    this.fetchMagazines()
    this.fetchWorkspaces()
    this.fetchGuidebooks()
    this.fetchNews()
    this.setBreadcrumbs(this.breadcrumbs)
  },
  methods: {
    ...mapActions({
      setBreadcrumbs: 'breadcrumbs/set',
      optionsFetch: 'options/fetch',
      areaFetch: 'area/fetch',
    }),
    peeepsCategory(txt) {
      switch (txt) {
        case 'food':
          return '食事'
        case 'shopping':
          return '買い物'
        case 'sightseeing':
          return '観光'
        case 'experience':
          return '体験・アクティビティ'
        case 'culture':
          return '文化'
        case 'event':
          return 'イベント'
        case 'stay':
          return '住宅・宿泊'
        case 'job':
          return '仕事'
        case 'visa-license':
          return '免許'
        case 'medical':
          return '医療'
        case 'education':
          return '教育'
        case 'communication':
          return '会話'
        default:
          return '全て'
      }
    },
    async fetchMagazines() {
      const magazines = await this.$contentful
        .getEntries({
          locale: this.$i18n.locale,
          content_type: 'magazine',
          order: '-fields.publishDate',
          limit: 4,
          select:
            'sys.id,sys.contentType,fields.image,fields.thumbnail,fields.title,fields.publishDate,fields.tags,fields.color',
        })
        .then((res) => {
          return res.items
        })
        .catch((err) => {
          this.$sentry.captureException(err)
          return []
        })
      this.magazines = magazines
    },
    async fetchWorkspaces() {
      const workspaces = await this.$contentful
        .getEntries({
          locale: this.$i18n.locale,
          content_type: 'workspace',
          order: '-fields.publishDate',
          'fields.name[exists]': true,
          'fields.thumbnail[exists]': true,
          limit: 4,
          select:
            'sys.id,fields.thumbnail,fields.name,fields.body,fields.list,fields.prefecture,fields.options',
        })
        .then((res) => {
          return res.items
        })
        .catch((err) => {
          this.$sentry.captureException(err)
          return []
        })
      this.workspaces = workspaces
    },
    async fetchGuidebooks() {
      const peeepsFirebase = (() => {
        try {
          return this.$fireModule.app('peeeps')
        } catch (e) {
          return this.$fireModule.initializeApp(
            {
              apiKey: 'AIzaSyD_4tOIPjNeTzAQP8ffEWNAAnZEKz36ePA',
              authDomain: 'peeeps-72f2f.firebaseapp.com',
              databaseURL: 'https://peeeps-72f2f.firebaseio.com',
              projectId: 'peeeps-72f2f',
              storageBucket: 'peeeps-72f2f.appspot.com',
              messagingSenderId: '527794551595',
              appId: '1:527794551595:web:94f159023fe9933d5f236e',
              measurementId: 'G-64XDMH7GSG',
            },
            'peeeps'
          )
        }
      })()
      const peeepsFirestore = peeepsFirebase.firestore()
      const peeepsDocs = await peeepsFirestore
        .collection(`tours`)
        .where('draft', '==', false)
        .where('suspended', '==', false)
        .limit(6)
        .get()
        .then((toursCol) => toursCol.docs)
        .catch((error) => {
          this.$sentry.captureException(error)
          return []
        })

      const guidebooks = peeepsDocs.map((doc) => {
        return {
          id: doc.id,
          title: doc.get('title'),
          image: doc.get('image'),
          spots: doc.get('spots'),
          tags: doc.get('tags_ja'),
          category: doc.get('category'),
        }
      })
      this.guidebooks = guidebooks
    },
    async fetchNews() {
      const news = await this.$contentful
        .getEntries({
          locale: this.$i18n.locale,
          content_type: 'news',
          order: '-fields.publishDate',
          'fields.title[exists]': true,
          limit: 6,
          select:
            'sys.id,fields.thumbnail,fields.title,fields.publishDate,fields.category',
        })
        .then((res) => {
          return res.items
        })
        .catch((err) => {
          this.$sentry.captureException(err)
          return []
        })
      this.news = news
    },
    async fetchSpecial() {
      const special = await this.$contentful
        .getEntries({
          locale: this.$i18n.locale,
          content_type: 'special',
          order: '-fields.publishDate',
          'fields.pickup': true,
          limit: 24,
          select: 'sys.id,fields.title,fields.thumbnail,fields.link',
        })
        .then((res) => {
          return res.items
        })
        .catch((err) => {
          this.$sentry.captureException(err)
          return []
        })
      this.special = special.length < 6 ? [...special, ...special] : special
    },
  },
}
