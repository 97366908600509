import { render, staticRenderFns } from "./TopSection.vue?vue&type=template&id=36988414&scoped=true&"
import script from "./TopSection.vue?vue&type=script&lang=js&"
export * from "./TopSection.vue?vue&type=script&lang=js&"
import style0 from "./TopSection.vue?vue&type=style&index=0&id=36988414&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_advha552eakciyizojust5gnre/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36988414",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/runner/work/workation-web/workation-web/app/components/common/Header.vue').default,IconLink: require('/home/runner/work/workation-web/workation-web/app/components/figure/IconLink.vue').default})
