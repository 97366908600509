const workationsHrefRegexp = /^https:\/\/workation.app\//

const isExternal = (href: string): boolean => {
  const httpHrefRegexp = /^https?:\/\//
  return !workationsHrefRegexp.test(href) && httpHrefRegexp.test(href)
}

const externalToInternal = (href: string) => {
  if (!workationsHrefRegexp.test(href)) {
    return href
  }
  return href.replace(workationsHrefRegexp, '')
}

export { isExternal, externalToInternal }
