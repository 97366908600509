
export default {
  props: {
    pageName: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    yellow: {
      type: Boolean,
      default: false,
    },
    external: {
      type: Boolean,
      default: false,
    },
  },
}
