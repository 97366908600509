import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4d4c67dc&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=4d4c67dc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_advha552eakciyizojust5gnre/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d4c67dc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SliderItem: require('/home/runner/work/workation-web/workation-web/app/components/common/SliderItem.vue').default,Slider: require('/home/runner/work/workation-web/workation-web/app/components/common/Slider.vue').default,Header: require('/home/runner/work/workation-web/workation-web/app/components/common/Header.vue').default,IconPin: require('/home/runner/work/workation-web/workation-web/app/components/figure/IconPin.vue').default,IconChevronRightBox: require('/home/runner/work/workation-web/workation-web/app/components/figure/IconChevronRightBox.vue').default,TopSection: require('/home/runner/work/workation-web/workation-web/app/components/pages/top/TopSection.vue').default,WorkspaceCard: require('/home/runner/work/workation-web/workation-web/app/components/pages/workspace/WorkspaceCard.vue').default,GuidebookCard: require('/home/runner/work/workation-web/workation-web/app/components/pages/guidebook/GuidebookCard.vue').default,Pickups: require('/home/runner/work/workation-web/workation-web/app/components/common/Pickups.vue').default,Side: require('/home/runner/work/workation-web/workation-web/app/components/common/Side.vue').default})
