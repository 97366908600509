import { render, staticRenderFns } from "./GuidebookCard.vue?vue&type=template&id=d4a8f498&scoped=true&"
import script from "./GuidebookCard.vue?vue&type=script&lang=js&"
export * from "./GuidebookCard.vue?vue&type=script&lang=js&"
import style0 from "./GuidebookCard.vue?vue&type=style&index=0&id=d4a8f498&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_advha552eakciyizojust5gnre/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4a8f498",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AttrLabel: require('/home/runner/work/workation-web/workation-web/app/components/common/AttrLabel.vue').default})
