
export default {
  props: {
    workspace: {
      type: Object,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    thumbnail() {
      return this.workspace.fields.thumbnail.fields.file.url
    },
    optionsList() {
      return this.workspace.fields.options.map((option) => option.fields.name)
    },
    prefecture() {
      return this.workspace.fields.prefecture.fields.name
    },
  },
}
