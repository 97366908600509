
export default {
  props: ['guidebook'],
  computed: {
    title() {
      return this.shortTitle(this.guidebook.title)
    },
    spotsCount() {
      if (!this.guidebook.spots.length) {
        return 0
      }
      return this.guidebook.spots.length
    },
    category() {
      if (!this.guidebook.category) {
        return ''
      }
      return this.guidebook.category
    },
    link() {
      return `https://spot.peeeps.app/ja/tours/${this.guidebook.id}/`
    },
  },
  mounted() {},
  methods: {
    shortTitle(text) {
      return text.length > 36 ? `${text.slice(0, 36)}...` : text
    },
  },
}
