
import { isExternal } from '~/plugins/checkExternal'

export default {
  props: {
    heading: {
      type: String,
      default: '',
    },
    small: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
  },
  computed: {
    external() {
      return isExternal(this.href)
    },
  },
}
